/**
 * ImageNextGenFormat API
 */

const ImageNextGenFormat = ($ => ({
  serveWebPImage: () => {
    const ext = /\.(jpe?g|png|gif)$/
    const $imgs = $('img')
    $imgs.each(function img() {
      const $this = $(this)
      const src = $this.attr('src') || $this.data('src')

      // explicit avoidance of webp for various reasons
      const nowebp = $this.data('no-webp')

      if (!nowebp) {
        $this
          .wrap($('<picture/>'))
          .before($('<source/>', {
            type: 'image/webp',
            srcset: src.replace(ext, '.webp'),
          }))
      }
    })
  },
}))(jQuery)

export default ImageNextGenFormat
