import Assistants from './main/Assistants'
import BigSlide from './main/BigSlide'
import Carousel from './main/Carousel'
import HighlightDirectory from './main/HighlightDirectory'
import HomeWidgets from './main/HomeWidgets'
import ImageNextGenFormat from './main/ImageNextGenFormat'
import NewsSearchResults from './main/NewsSearchResults'
import PeopleSearchResults from './main/PeopleSearchResults'
import RelatedPanels from './main/RelatedPanels'
import RetinaJS from './main/Retina'
import Share from './main/Share'
import SiteSearchResults from './main/SiteSearchResults'
import UnveilBackgroundImage from './main/UnveilBackgroundImage'
import UnveilImage from './main/UnveilImage'

/**
 * MS API
 */

const MS = ($ => {
  /**
   * Activate a function if an HTML attribute
   * is present in the web page
   *
   * @param {string}   attr HTML attribute
   * @param {function} fn   function
   */
  function activate(attr, fn) {
    $(attr).length && typeof fn === 'function' && fn()
  }

  return {
    init: () => {
      Assistants.checkForJs('html', 'no-js')
      Assistants.checkForJs('body', 'no-js')
      Assistants.showHide('js-site-search-btn', 'js-site-search', 'js-hidden', false)
      Assistants.smartSearchInput('js-smart-search-input', '')
      Assistants.smartSearchInput('js-smart-search-input-re-search', '')
      Assistants.toggleMenu()
      activate('img', ImageNextGenFormat.serveWebPImage)
      activate('.js-directory-home', HomeWidgets.init)
      activate('.js-generic-search-results', SiteSearchResults.init)
      activate('.js-carousel', Carousel.init)
      activate('.js-directory', PeopleSearchResults.init)
      activate('.js-directory-news', NewsSearchResults.init)
      activate('.js-share', Share.init)
      activate('.isotope', RelatedPanels.init)
      activate('#NavPrimary', HighlightDirectory.init)
      activate('body', BigSlide.init)
      activate('body', RetinaJS.init)
      activate('body', UnveilBackgroundImage.init)
      activate('body', UnveilImage.init)

      $(() => {
        $('img').unveil(10)
        $('.bannerItem').unveilBg(10)
        $('.menu-link').bigSlide({
          menuWidth: '13em',
        })
      })
    },
  }
})(jQuery)

export default MS
