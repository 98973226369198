/**
 * RetinaJS
 */

const RetinaJS = (() => ({
  init: () => {
    const patt = /\.\w+$/
    const env = typeof exports === 'undefined' ? window : exports
    const options = {
      retinaImageSuffix: '@2x',
      checkMimeType: true,
      forceOriginalDimensions: true,
    }

    /**
   * @param {string} t additional suffix
   */
    function suffix(t) {
      return options.retinaImageSuffix + t
    }

    class RetinaImagePath {
      constructor(src, at2x) {
        this.path = src || ''

        if (at2x == null) {
          if (document.createElement) {
            const path = this.path.split('?')
            path[0] = path[0].replace(patt, suffix)
            this.at2xPath = path.join('?')
          } else {
            const link = document.createElement('a')
            link.href = this.path
            link.pathname = link.pathname.replace(patt, suffix)
            this.at2xPath = link.href
          }

          this.performCheck = true
        } else {
          this.at2xPath = at2x
          this.performCheck = false
        }
      }

      isExternal() {
        return !(!this.path.match(/^https?:/i) || this.path.match(`//${document.domain}`))
      }

      /**
     * @param {function} cb callback
     */
      check2xVariant(cb) {
        let ajax
        const $this = this

        if (this.isExternal()) return cb(false)
        if (this.performCheck || this.at2xPath == null) {
          if (this.at2xPath in RetinaImagePath.confirmedPaths) return cb(true)

          ajax = new XMLHttpRequest()
          ajax.open('HEAD', this.at2xPath)
          ajax.onreadystatechange = () => {
            if (ajax.readyState !== 4) return cb(false)
            if (ajax.status >= 200 && ajax.status <= 399) {
              if (options.checkMimeType) {
                const response = ajax.getResponseHeader('Content-Type')
                if (response === null || !response.match(/^image/i)) return cb(false)
              }

              RetinaImagePath.confirmedPaths.push($this.at2xPath)
              return cb(true)
            }

            return cb(false)
          }

          ajax.send()
        }

        return cb(true)
      }
    }

    RetinaImagePath.confirmedPaths = []

    class RetinaImage {
      constructor(elem) {
        this.el = elem

        const src = this.el.getAttribute('src') || this.el.getAttribute('data-src')
        this.path = new RetinaImagePath(src, this.el.getAttribute('data-at2x'))

        this.path.check2xVariant(t => {
          t && this.swap()
        })
      }

      swap(at2xPath = this.path.at2xPath) {
        const retImg = this;
        (function timed() {
          if (retImg.el.complete) {
            if (options.forceOriginalDimensions) {
              retImg.el.setAttribute('width', retImg.el.offsetWidth)
              retImg.el.setAttribute('height', retImg.el.offsetHeight)
            }

            retImg.el.setAttribute('src', at2xPath)
          } else {
            setTimeout(timed, 5)
          }
        })()
      }
    }

    class Retina {
      static configure(opts = {}) {
        Object.keys(opts).forEach(e => {
          options[e] = opts[e]
        })
      }

      static init(t) {
        const n = t || env
        const e = n.onload || (() => {})

        n.onload = () => {
          let img
          const s = []
          const imgs = document.getElementsByTagName('img')

          for (let i = 0; i < imgs.length; i++) {
            img = imgs[i]
            img.getAttributeNode('data-no-retina') || s.push(new RetinaImage(img))
          }

          e()
        }
      }

      static isRetina() {
        return env.devicePixelRatio > 1 ||
             !(!env.matchMedia ||
                 !env.matchMedia('(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)').matches)
      }
    }

    env.Retina = Retina
    env.RetinaImage = RetinaImage
    env.RetinaImagePath = RetinaImagePath
    Retina.isRetina() && Retina.init()
  },
}))()

export default RetinaJS
