import Assistants from './Assistants'

/**
 * Share API
 */

const Share = ($ => ({
  init: () => {
    $('.share-buttons').find('a').on('click', function (e) {
      const $this = $(this)
      window.open($this.attr('href'), '', 'width=580, height=470')
      e.preventDefault()
    })

    Assistants.showHide('js-btn-share', 'js-share', 'js-hidden', false)
  },
}))(jQuery)

export default Share
