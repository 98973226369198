/**
 * Assistants API
 *
 * Collection of utility methods
 */

const Assistants = ($ => ({
  checkIfElemIsEmpty: elem => $.trim($(elem).html()) === '' || undefined,
  checkForJs: (elem, cls) => {
    const $elem = $(elem)
    $elem.hasClass(cls) && $elem.removeClass(cls)
  },
  toggleMenu: () => {
    const $jsNavToggle = $('.js-nav-toggle')
    const $jsNavSectors = $(`.${$jsNavToggle.attr('data-toggle-for')}`)

    $jsNavToggle.on('click', e => {
      $jsNavSectors.toggleClass('is-active')
      $jsNavSectors.stop(true, true).slideToggle(200)
      e.preventDefault()
    })
  },
  smartSearchInput: (wrapper, text) => {
    const $btn = $(`.${wrapper}`).find('.btn')
    const $wrapper = $(`.${wrapper}`)

    $btn.hide()
    $wrapper.append(`<a href="#" class="icon js-btn-append">${text}</a>`)
    $('.js-btn-append').on('click', function click(e) {
      e.preventDefault()
      $(this).parents().eq(1).find('input[type="submit"')
        .trigger('click')
    })
    $wrapper.on('keyup', function keyup(e) {
      if ((e.keyCode ? e.keyCode : e.which) === 13) {
        $(this).parents().eq(1).find('input[type="submit"')
          .trigger('click')
      }
    })
  },
  getQueryString: () => {
    const filtered = {}
    const query = document.URL.split('?')[1]

    if (query != null) {
      const queryArray = query.split('&')
      for (let i = 0; i < queryArray.length; i++) {
        const [key, value] = queryArray[i].split('=')
        filtered[key] = value
      }
    }

    return filtered
  },
  showCounts: (wrap, dest) => {
    const $wrap = $(`.${wrap}`)
    const $dest = $(`.${dest}`)

    $wrap.hide()
    if ($wrap.length) {
      $dest.html($wrap.html())
    } else {
      $dest.html('<strong>1</strong> / <strong>1</strong>')
    }
  },
  showHide: (wrap, elem, cls, target) => {
    function visible() {
      return $(`.${elem}`).hasClass(cls) ? 'hidden' : 'visible'
    }

    let vis
    const $wrap = $(`.${wrap}`)
    const $elem = $(`.${elem}`)

    $wrap.on('click', e => {
      e.preventDefault()
      $elem.toggleClass(cls)
      vis = visible()

      if (target && vis === 'visible') {
        $('body').append('<div class="overlay"></div>')
        $('.overlay').height($(window).height())
      } else {
        $('.overlay').remove()
        if ($elem.is('input') || $elem.find('input').length > 0) {
          $elem.find('input').trigger('focus').attr('placeholder', 'Search site')
        }
      }
    })

    $(document).on('click', e => {
      vis = visible()
      if (!$(e.target).is($wrap) && !$(e.target).is($elem) &&
        !$(e.target).is($elem.find('input')) &&
        !$(e.target).is($('.btn')) && vis === 'visible') {
        $elem.addClass(cls)
        $('.overlay').remove()
      }
    })
  },
}))(jQuery)

export default Assistants
