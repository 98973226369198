import Assistants from './Assistants'

/**
 * NewsSearchResults API
 */

const NewsSearchResults = ($ => {
  const locus = window.location
  const hash0 = 'searchtext'
  const hash1 = 'searchmode=anyword'
  const form = '#NewsSearch'
  const $input = $('#NewsSearch_input')
  const $h1 = $('#NewsSearch_dataDiv')

  const getSearchParams = () => {
    const input = $input.val().trim()
    const encoded = encodeURIComponent(input)
    return `?${hash0}=${encoded}&${hash1}`
  }

  const setupSearchInput = () => {
    const query = Assistants.getQueryString()
    let value = null
    if ('searchtext' in query) {
      value = decodeURIComponent(query.searchtext)
    }

    value && $h1.length && $h1.html(`Search results for: <em>${value}</em>`)
  }

  const processForm = () => {
    const query = getSearchParams().trim()
    const pathname = '/news/search-results'
    const path = `//${locus.host}${pathname}${query}`

    $(form).attr('action', path)
    $(form).submit()
    locus.href = path
  }

  return {
    init: () => {
      setupSearchInput()
      if (Assistants.checkIfElemIsEmpty('#NewsSearch_dataDiv') &&
          !Assistants.checkIfElemIsEmpty('.js-search-results-tag')) {
        $('#NewsSearch_dataDiv').html($('.js-search-results-tag').html())
      }

      $(`${form} .js-btn-submit`).on('click', e => {
        processForm()
        e.preventDefault()
      })
      $('.main-content').addClass('cms-blogpost')
    },
  }
})(jQuery)

export default NewsSearchResults
