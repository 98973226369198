/**
 * HighlightDirectory API
 */

const HighlightDirectory = ($ => {
  const $nav = $('#NavPrimary')
  const $menu = $('#menuType')
  const $subMenu = $('#menuSubType')

  const getMenu = () => {
    const $lists = $nav.children()
    const type = $menu.val().toLowerCase()

    $lists.filter(function menu() {
      return $(this).find('a').attr('title').toLowerCase() === type
    }).addClass('Highlighted')
  }

  const getSubMenu = () => {
    const $subLists = $nav.children().find('li')
    const subtype = $subMenu.val().toLowerCase()

    $subLists.length && $subLists.filter(function submenu() {
      return $(this).find('a').text().toLowerCase() === subtype
    }).addClass('Highlighted')
  }

  const setDirectoryType = () => {
    $menu.val().toLowerCase() === 'people' && $('.main-content').addClass('ms-directory')
  }

  return {
    init: () => {
      if ($menu.length) {
        getMenu()
        setDirectoryType()
      }

      $subMenu.length && getSubMenu()
    },
  }
})(jQuery)

export default HighlightDirectory
