/**
 * jQuery Unveil Image
 */

const UnveilImage = ($ => ({
  init: () => {
    $.fn.unveil = function (height, fn) {
      let $this = this
      const $window = $(window)
      const src = window.devicePixelRatio > 1 ? 'data-src-retina' : 'data-src'

      function cb() {
        const unveilcb = $this.filter(function () {
          const $this2 = $(this)
          if (!$this2.is(':hidden')) {
            const scrollTop = $window.scrollTop()
            const padHeight = scrollTop + $window.height()
            const offsetTop = $this2.offset().top
            const topHeight = offsetTop + $this2.height()

            return scrollTop - height <= topHeight && offsetTop <= padHeight + height
          }

          return false
        })
        $this = $this.not(unveilcb.trigger('unveil'))
      }

      this.one('unveil', function () {
        const finalSrc = this.getAttribute(src) || this.getAttribute('data-src')
        if (finalSrc) {
          this.setAttribute('src', finalSrc)
          typeof fn === 'function' && fn.call(this)
        }
      })

      $window.on('scroll.unveil resize.unveil lookup.unveil', cb)
      cb()
      return this
    }
  },
}))(jQuery)

export default UnveilImage
