/**
 * RelatedPanels API
 */

const RelatedPanels = ($ => ({
  init: () => {
    $('.isotope').isotope({
      itemSelector: '.panel-related',
      layoutMode: 'masonry',
      transitionDuration: 0,
    })
  },
}))(jQuery)

export default RelatedPanels
