/**
 * HomeWidgets API
 */

const HomeWidgets = ($ => {
  /**
   * Gets the first element descendant of the parent
   *
   * @param {string} parent   parent element
   * @param {string} selector element to filter
   * @param {number} [index]  which parent to use? default is eq(0)
   *
   * @returns {jQueryHTMLElement}
   */
  const firstElem = (parent, selector, index = 0) => $(parent).eq(index).find(selector).eq(0)

  /**
   * Empties parent's innerHTML and place args
   *
   * @param {string}                parent parent element
   * @param {number}                index  which parent to use
   * @param  {...jQueryHTMLElement} args   jQuery elements to place after emptying the parent
   *
   * @returns {void}
   */
  const revealSelected = (parent, index, ...args) => {
    $(parent)
      .eq(index)
      .empty()
      .html(args)
      .show()
  }

  /**
   * Sets all matched selector elements to same innerHeight
   * @param {string} selector selector items
   *
   * @returns {void}
   */
  const uniformHeight = selector => {
    const heights = $(selector).map(function () {
      return $(this).innerHeight()
    })

    $(selector).innerHeight(Math.max(...heights))
  }

  return {
    init: () => {
      for (let i = 0; i < $('.services').length; i++) {
        revealSelected('.services', i, firstElem('.services', 'p', i))
        // revealSelected() is not needed for '.news' since we're using summary
      }

      revealSelected('.about', 0, firstElem('.about', 'style'), firstElem('.about', 'p'))
      uniformHeight('.news')
      uniformHeight('.services')

      $(window).on('resize', () => {
        uniformHeight('.news')
        uniformHeight('.services')
      })
    },
  }
})(jQuery)

export default HomeWidgets
