/**
 * jQuery BigSlide
 */

const BigSlide = ($ => ({
  init: () => {
    $.fn.bigSlide = function (opts) {
      const options = $.extend({
        menu: '#menu',
        push: '.push',
        side: 'left',
        menuWidth: '15.625em',
        speed: 300,
        activeBtn: 'menu-open',
      }, opts)
      const $this = this
      const $menu = $(options.menu)
      const $push = $(options.push)
      const width = options.menuWidth
      const cssOpts = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        'settings.side': `-${options.menuWidth}`,
        width: options.menuWidth,
        height: '100%',
      }
      const transitions = {
        '-webkit-transition': `${options.side} ${options.speed}ms ease`,
        '-moz-transition': `${options.side} ${options.speed}ms ease`,
        '-ms-transition': `${options.side} ${options.speed}ms ease`,
        '-o-transition': `${options.side} ${options.speed}ms ease`,
        transition: `${options.side} ${options.speed}ms ease`,
      }

      $menu.css(cssOpts)
      $push.css(options.side, 0)
      $menu.css(transitions)
      $push.css(transitions)

      $menu.state = 'closed'
      $menu.open = () => {
        $menu.state = 'open'
        $menu.css(options.side, 0)
        $push.css(options.side, width)
        $this.addClass(options.activeBtn)
      }

      $menu.close = () => {
        $menu.state = 'closed'
        $menu.css(options.side, `-${width}`)
        $push.css(options.side, 0)
        $this.removeClass(options.activeBtn)
      }

      $(document).on('click.bigSlide', e => {
        if (!$(e.target).closest($this).length && $menu.state === 'open') {
          $menu.close()
          $this.removeClass(options.activeBtn)
        }
      })

      $this.on('click.bigSlide', e => {
        e.preventDefault()
        $menu.state === 'closed' ? $menu.open() : $menu.close()
      })

      $this.on('touchend', e => {
        $this.trigger('click.bigSlide')
        e.preventDefault()
      })

      return $menu
    }
  },
}))(jQuery)

export default BigSlide
