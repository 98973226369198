import Assistants from './Assistants'

/**
 * SiteSearchResults API
 */

const SiteSearchResults = ($ => {
  const locus = window.location
  const hash0 = 'searchtext'
  const hash1 = 'searchmode=anyword'
  const formInput = '#SearchSiteForm'
  const formReinput = '#SearchSiteAgain'
  const input = '#SearchSiteForm_input'
  const reinput = '#SearchSiteAgain_input'
  const $h1 = $('#SearchSite_dataDiv')
  let formInputUsed = false

  const getSearchParams = () => {
    const inputs = $(input).val().trim()
    const reinputs = $(reinput).length ? $(reinput).val().trim() : ''
    if ((!inputs && !reinputs) || inputs) {
      formInputUsed = true
    }

    const encoded = encodeURIComponent(formInputUsed ? inputs : reinputs)
    return `?${hash0}=${encoded}&${hash1}`
  }

  const setupSearchInput = () => {
    const query = Assistants.getQueryString()
    let value = null
    if ('searchtext' in query) {
      value = decodeURIComponent(query.searchtext)
    }

    value && $h1.length && $h1.html(`Search results for: <em>${value}</em>`)
  }

  const processForm = () => {
    const query = getSearchParams().trim()
    const pathname = '/search'
    const path = `//${locus.host}${pathname}${query}`

    formInputUsed ? $(formInput).attr('action', path) : $(formReinput).attr('action', path)
    formInputUsed ? $(formInput).submit() : $(formReinput).submit()
    locus.href = path
  }

  return {
    init: () => {
      setupSearchInput()
      $(`${formInput} .js-btn-submit`).on('click', e => {
        processForm()
        e.preventDefault()
      })

      $(`${formReinput} .js-btn-submit`).on('click', e => {
        processForm()
        e.preventDefault()
      })
    },
  }
})(jQuery)

export default SiteSearchResults
