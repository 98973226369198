import Assistants from './Assistants'

/**
 * PeopleSearchResults API
 */

const PeopleSearchResults = ($ => {
  const locus = window.location
  const hash = 'searchtext=&searchmode=anywordorsynonyms&name'
  const form = '#searchPeople'
  const $inputs = ($('.js-directory-submit').find('input'), $('.js-directory-form-inputs'))
  const $SearchFilterField = $('.SearchFilterField')
  const $input = $('.js-directory-searchable-input').find('input')
  const $tags = $('.js-search-tags')

  const appendStar = () => {
    let trimmed = $('.js-directory-input-clone').val().trim()
    const d = trimmed

    trimmed.slice(-1) === '*' && (trimmed = trimmed.slice(0, -1))
    const e = trimmed.trim().split(' ')
    if (trimmed.length > 0 && trimmed[trimmed.length - 1] !== '*') {
      const f = trimmed.match(/(["'])(?:[^\1\\]|\\.)*?\1/)
      if (f == null) {
        for (let i = 0; i < e.length; i++) {
          e[i] += '*'
        }
      }

      trimmed = `${e.join(' ')} fullname:(${d.replace(/"/g, '')}*)`
      mstphPeopleKeywordSearchOnlyOnName && (trimmed += ` -(profiletext:(${d.replace(/"/g, '')}*) NOT fullname:(${d.replace(/"/g, '')}*))`)
    }

    $input.val(trimmed)
    return trimmed
  }

  const getURLParameter = str => decodeURIComponent((RegExp(`[?|&]${str}=(.+?)(&|$)`).exec(locus.search) || [null, null])[1])

  const getSearchParams = () => {
    const starred = encodeURIComponent(appendStar())
    return `?${hash}=${starred}`
  }

  const setURLParameters = (str, url) => {
    let params

    if (getURLParameter(str) !== 'null') {
      params = locus.search.replace(new RegExp(`([?|&]${str}=)(.+?)(&|$)`), `$1${encodeURIComponent(url)}$3`)
      if (str === 'service' || str === 'sector') {
        params = params.replace(new RegExp(`([?|&]${str}id=)(.+?)(&|$)`), '$1$3')
      }
    } else if (locus.search.length) {
      params = `${locus.search}&${str}=${encodeURIComponent(url)}`
    } else {
      params = `?${str}=${encodeURIComponent(url)}`
    }

    locus.href = `${locus.protocol}//${locus.host}${locus.pathname}${params}`
  }

  const showCounts = (source, dest) => {
    const $source = $(`.${source}`)
    const $dest = $(`.${dest}`)
    const counts = $('#directory-first-only').val()

    $source.hide()
    if ($source.length) {
      $dest.html($source.html())
    } else {
      $dest.html(counts)
    }
  }

  const buildTag = (text, type) => `<a href="#" class="tag tag-icon" data-type=${type} title="Remove this search filter">${text}</a>`

  const populateTags = () => {
    const input = $('.js-directory-input-clone').val()
    input && $tags.append(buildTag(input, 'searchtext'))
  }

  const removeTag = (e, tag) => {
    e.preventDefault()
    const type = $(tag).data('type')
    type === 'searchtext' ? setURLParameters(hash, '') : setURLParameters(type, -1)
  }

  const appendReset = () => {
    const search = `<a href="#" class="tag-icon tag-secondary js-btn-reset">${$('#msSearchLabels').val().split('|')[1]}</a>`
    $tags.find('a').length && $tags.append(search)
  }

  const prependTextBefore = () => {
    const text = `<p>${$('#msSearchLabels').val().split('|')[0]}</p>`
    $tags.prepend(text)
  }

  const setupSearchInput = () => {
    const term = $SearchFilterField.val()
    const $clone = $('<div class="form-group input-icon input-icon-search"><input type="text" class="SearchFilterField js-directory-input-clone input--text" placeholder=""><a href="#" class="icon js-btn-append js-btn-submit"></a></div>')

    $inputs.append($clone)
    if ($('.js-directory-input-clone').length === 1) {
      const parsed = term.replace(/\*/g, '')
      const len = parsed.indexOf('fullname:(')
      $('.js-directory-input-clone').val(parsed.substring(0, len - 1))
    }

    $SearchFilterField.hide()
    $clone.on('keypress', e => {
      if ((e.keyCode ? e.keyCode : e.which) === 13) {
        e.preventDefault()
        $(`${form} .js-btn-submit`).trigger('click')
      }
    })

    populateTags()
    Assistants.checkIfElemIsEmpty($tags) ? $('#results .strip').hide() : appendReset()
  }

  const getDirectoryType = () => $('#directoryType').val()

  const processForm = () => {
    const query = getSearchParams().trim()
    const type = getDirectoryType()
    const path = `//${locus.host}/${type}${query}#results`

    $(form).attr('action', path)
    $(form).submit()
    locus.href = path
  }

  const resetForm = () => {
    locus.href = `${locus.protocol}//${locus.host}${locus.pathname}`
  }

  return {
    init: () => {
      if (locus.href.indexOf('page=') > -1 && locus.href.indexOf('#results') === -1) {
        locus.href += '#results'
      }

      showCounts('js-page-results-data', 'js-page-results-display')
      setupSearchInput()

      $('.js-search-tags a').on('click', function tag(e) {
        removeTag(e, this)
      })

      $('.js-btn-reset').on('click', () => {
        resetForm()
      })

      $(`${form} .js-btn-submit`).on('click', e => {
        processForm()
        e.preventDefault()
      })

      if ($tags.find('a').length) {
        let c = false
        if (!c) {
          prependTextBefore()
          c = true
        }
      }

      $('.search-results-null').length && $('.js-page-results-display').hide()

      $(() => {
        const text = 'Enter a name'
        const $source = $('.SearchFilterField')
        $source.eq(0).addClass('placeholderDirectory')
        $source.attr('placeholder', text)
      })
    },
  }
})(jQuery)

export default PeopleSearchResults
