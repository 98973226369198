/**
 * Carousel API
 *
 * Manages the Home carousel
 */

const Carousel = ($ => ({
  init: () => {
    const $jsCarousel = $('.js-carousel')

    $(() => {
      $jsCarousel
        .addClass('owl-carousel')
        .owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          autoplay: true,
          autoplayHoverPause: true,
          nav: true,
          navElement: 'div',
          navContainerClass: 'owl-buttons',
          dotClass: 'owl-page',
          dotsClass: 'owl-pagination',
          onInitialized: () => {
            const $stage = $('.owl-stage')
            const navContainer = '.owl-buttons'
            const dotsContainer = '.owl-pagination'
            const controlsContainerClass = 'owl-controls clickable'

            $stage.show()
            $(`${navContainer}, ${dotsContainer}`).wrapAll($('<div/>', {
              class: controlsContainerClass,
            }))
          },
        })
    })
  },
}))(jQuery)

export default Carousel
